<template>
  <div class="support">
    <img
      src="../../public/assets/images/palestine-flag-icon.webp"
      alt=""
      class="support-flag"
    />
    <p class="support-phrase">{{ $t("supportPalestine") }}</p>
  </div>
</template>

<script>
export default {
  name: "SupportPalestine",
}
</script>

<style lang="scss" scoped>
.support {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 4rem;
  background-color: #303d4d;
  position: fixed;
  top: 0;
  z-index: 100;
  &-flag {
    width: 28px;
  }
  &-phrase {
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    color: #fff;
  }
}
</style>
