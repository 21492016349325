<template>
  <a id="lang-btn" @click="changeLanguage" :title="$t('Change Language')">
    <img :src="langPathImg" />
  </a>
</template>

<script>
import GlobalMixin from "@/GlobalMixin.js"

export default {
  name: "locale-changer",
  mixins: [GlobalMixin],
  mounted() {
    document.documentElement.lang = this.$i18n.locale
    document.documentElement.dir = this.$i18n.locale === "en" ? "ltr" : "rtl"
    this.$vuetify.rtl = this.$i18n.locale === "ar"
  },
  methods: {
    changeLanguage() {
      localStorage.setItem(
        "preferred-lang",
        this.$i18n.locale === "en" ? "ar" : "en"
      )
      this.changeLang()
    },
  },
}
</script>
