<template>
  <footer>
    <div class="row">
      <div class="col-12 d-md-none pb-0">
        <router-link :to="{ name: 'FancyHome' }">
          <img :src="instituteLogo" alt="logo" class="logo" />
        </router-link>
      </div>
      <div class="footer-col col-md-4 order-last order-md-first">
        <router-link :to="{ name: 'FancyHome' }">
          <img
            :src="instituteLogo"
            alt="logo"
            class="logo d-none d-md-inline-block"
          />
        </router-link>
        <h3 class="contact">{{ $t("footer.contactUs") }}</h3>
        <p>
          {{ $t("footer.Call") }} :
          <a class="phone" :href="`tel:${this.footerData.whatsappNumber}`">
            {{ this.footerData.whatsappNumber }}
          </a>
        </p>
        <p v-if="institute.id === 1">
          955 W John Carpenter FWY, Suite 100, Irving &nbsp;Tx&nbsp;75039
        </p>
        <p>
          {{ this.footerData.about }}
        </p>
        <a class="email" :href="`mailto:${this.footerData.contactUs}`">
          {{ $t("footer.Email") }}:
          {{ this.footerData.contactUs }}
        </a>
        <div class="d-flex" v-if="socialLinks.length">
          <a
            class="social-link-container"
            v-for="link in socialLinks"
            :key="link.id"
            :style="{ background: link.background }"
            :href="`${link.website}`"
            target="_blank"
          >
            <img
              :src="require(`../assets/images/footer/links/${link.logo}.svg`)"
              :alt="link.logo"
            />
          </a>
        </div>
      </div>
      <div class="footer-col col-md-5">
        <h3 class="explore">{{ $t("footer.Explore") }}</h3>
        <div class="row">
          <div
            v-for="(col, index) in websiteLinks"
            :key="index"
            :class="`col-md-${col.size}`"
            class="pb-0"
          >
            <ul class="d-flex flex-md-column flex-row">
              <template v-for="link in col.links">
                <li
                  :key="link.id"
                  v-if="!link.loggedIn && isKuttabOnly(link.isKuttabOnly)"
                >
                  <router-link
                    :to="{ name: link.componentName }"
                    v-if="!link.method.exists"
                  >
                    <span v-if="link.type === 'text'">
                      {{ $t(`footer.${link.text}`) }}
                    </span>
                    <span v-else>
                      <img class="footer-image" :src="link.text" />
                    </span>
                  </router-link>
                  <a @click="handleFunctionCall(link.method.name)" v-else>
                    <span v-if="link.type === 'text'">
                      {{ $t(`footer.${link.text}`) }}
                    </span>
                    <span v-else>
                      <img class="footer-image" :src="link.text" />
                    </span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-col col-md-3">
        <h3 class="subscribe">{{ $t("footer.Subscribe") }}</h3>
        <p class="subscribe-description">
          {{ this.footerData.subscribeText }}
        </p>
        <input
          type="email"
          :placeholder="$t('footer.emailHere')"
          v-model="email"
        />
        <button @click.prevent="subscribe">
          {{ $t("footer.subscribeNow") }}
        </button>
        <p class="d-none d-md-block">
          {{ $t("footer.Copyright") }}
        </p>
      </div>
    </div>
    <!-- Generic Modal -->
    <Modal
      :isOpened="modal"
      @closeModal="closeModal"
      :maxWidth="1000"
      :headerTitle="$t('termsAndConditions')"
      hideDefaultFooter
      :minHeight="700"
      persistent
    >
      <template #body>
        <iframe :src="termsLink" class="w-100 h-100"></iframe>
      </template>
    </Modal>
  </footer>
</template>

<script>
import { socialLinks, websiteLinks } from "../data/footer"
import Modal from "./global/Modal.vue"
import GlobalMixin from "@/GlobalMixin"

export default {
  name: "Footer",
  mixins: [GlobalMixin],
  components: { Modal },
  data() {
    return {
      termsLink: null,
      userType: this.$store.getters.StateUser?.user?.user_type,
      configs: JSON.parse(localStorage.getItem("configs")),
      institute: JSON.parse(localStorage.getItem("institute_configs")),
      email: null,
      modal: false,

      socialLinks,
      websiteLinks,
    }
  },
  mounted() {
    if (!localStorage.getItem("configs")) {
      this.getconfigurations().then(res => (this.configs = res))
    }
    this.websiteLinks.forEach(obj =>
      obj.links.forEach(link => {
        if (link.text === "Login" || link.text === "Register")
          return (link.loggedIn = this.userType)
      })
    )
  },
  methods: {
    /**
     * Get terms and conditions url to show
     */
    getUrls() {
      this.axios
        .get(`${process.env.VUE_APP_APIEndPoint}institutes/terms/`)
        .then(res => {
          this.termsLink = res.data.data.terms_and_conditions_url
        })
    },
    /**
     * Helper method to trigger another method
     * @param method string
     */
    handleFunctionCall(method) {
      this[method]()
    },
    /**
     * Open Terms and Conditions Dialog
     */
    openTermsAndConditions() {
      this.getUrls()
      this.modal = true
    },
    /**
     * Open google apps
     * @requires void
     */
    openGoogleApps() {
      location.href =
        "https://play.google.com/store/apps/details?id=com.alkuttab.digitling"
    },
    isKuttabOnly(isKuttabOnly) {
      if (isKuttabOnly) {
        if (this.institute.id === 1) {
          return true
        }
        return false
      }
      return true
    },
    /**
     * Close Modal
     * @param value boolean
     */
    closeModal(value) {
      this.modal = value
    },
    /**
     * Subscribe to news by entring email
     */
    subscribe() {
      if (!this.email) return this.showAlert("messages.emptyEmail", "error")

      const body = {
        email: this.email,
      }
      this.axios
        .post(
          `${process.env.VUE_APP_APIEndPoint}institutes/subscription/add/`,
          body,
          this.headers
        )
        .then(() => {
          this.showAlert(this.$t("messages.subscribed"))
          this.email = ""
        })
        .catch(err => this.formatErrors(err))
    },
  },
  computed: {
    /**
     * Gets institute logo for institute
     */
    instituteLogo() {
      return this.configs?.logo?.config_value
    },
    footerData() {
      return {
        whatsappNumber: this.configs?.whatsapp_number?.config_value,
        about: this.configs?.about?.config_value,
        contactUs: this.configs?.contactUs?.config_value,
        subscribeText: this.configs?.subscribe_text?.config_value,
      }
    },
  },
  watch: {
    /**
     * Watch for language Change to get the new Configs
     */
    "$i18n.locale"() {
      this.headers.headers["accept-language"] = localStorage.lang
      this.getconfigurations().then(res => {
        this.configs = res
        localStorage.setItem("configs", JSON.stringify(res))
        window.dispatchEvent(new Event("configs"))
      })
    },
  },
}
</script>

<style lang="scss" scoped>
footer {
  background: #cfebd1;
  padding: 46px 38px;
  font-size: 16px;
  .logo {
    width: 201px;
    margin-bottom: 27px;
  }
  .footer-col {
    h3 {
      color: $main-text-color;
      font-weight: $main-font-weight;
      font-size: $main-font-size;
      font-family: $font-family;
      line-height: 32px;
      margin-bottom: 16px;
      &.explore {
        padding-top: 4px;
        margin-bottom: 32px;
      }
      &.subscribe {
        margin-bottom: 8px;
      }
    }
    p,
    .phone {
      color: $sec-text-color;
      font-weight: $sec-font-weight;
      font-size: $sec-font-size;
      font-family: $font-family;
      line-height: 24px;
      text-decoration: none;
      &.subscribe-description {
        margin-bottom: 12px;
      }
    }
    .email {
      display: inline-block;
      color: #363a3d;
      margin-bottom: 6px;
      font-weight: $sec-font-weight;
      font-size: $sec-font-size;
      font-family: $font-family;
      line-height: 24px;
      text-decoration: none;
    }
    .social-link-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      border-radius: 5px;
      margin-inline-end: 16px;
      &:hover {
        filter: brightness(80%);
      }
    }

    ul {
      list-style: none;
      li {
        margin-bottom: 16px;
        a {
          text-decoration: none;
          color: $sec-text-color;
          font-weight: $sec-font-weight;
          font-size: $sec-font-size;
          line-height: 24px;
          font-family: "Roboto";
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    input {
      color: #6d737a;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.75);
      width: 100%;
      padding: 16px;
      font-family: $font-family;
      font-weight: $sec-font-weight;
      font-size: $sec-font-size;
      line-height: 24px;
      margin-bottom: 12px;
    }
    button {
      padding: 12px 30px;
      background: #5fae32;
      border-radius: 8px;
      color: #fff;
      font-family: $font-family;
      font-size: $sec-font-size;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 25px;
      &:hover {
        background: darken($color: #5fae32, $amount: 5);
      }
    }
    .footer-image {
      width: 150px;
    }
  }
}

@media (max-width: 992px) {
  $main-font-size: 16px;
  footer {
    padding: 16px;
    font-size: 12px;
    .logo {
      width: 164px;
      margin-bottom: 15px;
    }
    .footer-col {
      h3 {
        font-size: $main-font-size;
        line-height: 19px;
        &.contact {
          margin-bottom: 8px;
        }
        &.explore {
          margin-bottom: 8px;
        }
        &.subscribe {
          margin-top: 4px;
        }
      }
      p {
        margin-bottom: 8px;
      }
      ul {
        margin: 0;
        gap: 16px;
        li {
          margin: 0;
        }
      }
      input {
        display: block;
        max-width: 324px;
        border-radius: 4px;
        padding: 6px 16px;
      }
      button {
        padding: 11px 36px;
        line-height: 14px;
        margin-bottom: 4px;
      }
      .footer-image {
        width: 75px;
      }
    }
  }
}
</style>
