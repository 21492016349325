<template>
  <!--<div>
        <div class="topnav">
            <router-link to="/">{{$t('Home')}}</router-link> |
            <router-link to="/Register">{{$t('register')}}</router-link>
            <router-link to="/Login">{{$t('login')}}</router-link>
            <a href="#contact">{{$t('blog')}}</a>
            <a href="#about">{{$t('aboutUs')}}</a>
            <a href="#about">{{$t('courses')}}</a>
            <SwitchLang></SwitchLang>
        </div>
        <div>
            <transition name="slide" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
    </div>-->
  <div class="anonymous-layout">
    <v-progress-linear
      v-if="isRouteLoading"
      indeterminate
      color="cyan"
      style="z-index: 2000; top: -97px"
    ></v-progress-linear>
    <Header></Header>
    <transition name="slide" mode="out-in">
      <slot></slot>
    </transition>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import GlobalMixin from "@/GlobalMixin.js"
export default {
  mixins: [GlobalMixin],
  data() {
    return {}
  },
  name: "anonymous_layout",
  components: {
    Header,
    Footer,
  },
  computed: {
    isRouteLoading() {
      return this.$store.getters.GET_IS_ROUTE_LOADING
    },
  },
}
</script>

<style scoped lang="scss"></style>
